import { useMemo } from 'react';
import { Button, Flex, IconButton, Image, Link, Menu } from '@chakra-ui/react';
import { MenuButton, MenuDivider, MenuList, Text } from '@chakra-ui/react';
import { MenuItem, useColorMode, useMediaQuery } from '@chakra-ui/react';
import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

import ColorModeSwitcher from '../ColorModeSwitcher';

import { useSignInContext } from '../../context/SignIn';

import UserScopes from '../../config/userScopes';
import clearLocalStorage from '../../utils/clearLocalStorage';

import ProplessFC from '../../types/ProplessFC';
import Dict from '../../types/Dict';

import styleGenerator from './styles';

const memePaths: Dict<string> = {
  'Scrape Reddit Memes': '/r/memes',
  'Scrape Tiktok Memes': '/tiktok',
  'Scrape Twitter Memes': '/twitter',
  'Publish Instagram Memes': '/c/instagram',
  'Publish Scraped Memes': '/publish',
  'Publish Your Meme': '/add',
  'Review Reported Memes': '/memes/reported',
  'Template Memes': '/templates',
};

const memePathsArray: string[] = [
  'Publish Instagram Memes',
  'Publish Your Meme',
  'Scrape Tiktok Memes',
  'Scrape Reddit Memes',
  'Scrape Twitter Memes',
  'Publish Scraped Memes',
  'Review Reported Memes',
  'Template Memes',
];

const chatroomPaths: Dict<string> = {
  'Customer Support': '/customer/chatrooms',
  'Team Outreach': '/outreach/chatrooms',
  'AI Chatrooms': '/ai/chatrooms',
  'Community Chatrooms': '/community/chatrooms',
  'Customer Support Analytics': '/support/analytics',
};

const customerSupPaths: Dict<string> = {
  'Search Users': '/customer/search',
  'Review Users': '/customer/review',
  'User Mach': '/user/mach',
  'Claude': '/user/claude',
  'User Face embedding': '/user/face-embeddings',
  'User Map': '/user/map',
  'Mass Notifications': '/outreach/mass-notifications',
};

const ugcPaths: Dict<string> = {
  'All UGC Memes': '/memes/user',
  'Internal Review Memes': '/memes/user/internal-review',
  '"Review for Tagging" Memes': 'memes/user/additional-tagging',
};

const apiPaths: Dict<string> = {
  Sanic: '/api/sanic',
  Content: '/api/content',
  'The Iron Bank': '/api/iron',
  'Viserion UGC': '/api/viserion-ugc',
  'Drogon UGC': '/api/drogon-ugc',
  Vhagar: '/api/vhagar',
  'Vhagar UGC': '/api/vhagar-ugc',
  // 'Viserion AZ': '/api/viserion-az',
  // 'The Iron Bank App': '/api/iron-app',
  // 'Varys App': '/api/varys-app',
  // 'Viserion App': '/api/viserion-app',
  // Rhaegal: '/api/rhaegal',
  // 'Drogon AZ': '/api/drogon-az',
  // 'Varys AZ': '/api/varys-az',
  // 'The Iron Bank AZ': '/api/iron-az',
};

const NavigationBar: ProplessFC = () => {
  const { isSignedIn, setIsSignedIn, scopes } = useSignInContext();

  const { colorMode } = useColorMode();
  const styleProps = useMemo(
    () => styleGenerator(colorMode === 'dark'),
    [colorMode],
  );

  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');

  const signOut = () => {
    clearLocalStorage();
    setIsSignedIn(false);
  };

  const renderedMemeLinks: JSX.Element[] = [];
  if (scopes.includes(UserScopes.Post)) {
    for (const path of memePathsArray) {
      renderedMemeLinks.push(
        <MenuItem
          key={memePaths[path]}
          as={RouterLink}
          to={memePaths[path]}
          {...styleProps.link}
        >
          {path}
        </MenuItem>,
      );
    }
  }

  const renderedChatroomLinks: JSX.Element[] = [];
  if (scopes.includes(UserScopes.Chat)) {
    for (const path in chatroomPaths) {
      renderedChatroomLinks.push(
        <MenuItem
          key={chatroomPaths[path]}
          as={RouterLink}
          to={chatroomPaths[path]}
          {...styleProps.link}
        >
          {path}
        </MenuItem>,
      );
    }
  }

  const renderedAPILinks: JSX.Element[] = [];
  if (scopes.includes(UserScopes.Chat)) {
    for (const path in apiPaths) {
      renderedAPILinks.push(
        <MenuItem
          key={apiPaths[path]}
          as={RouterLink}
          to={apiPaths[path]}
          {...styleProps.link}
        >
          {path}
        </MenuItem>,
      );
    }
  }

  // Keeping bot chatroom seaprate from other chatrooms since the two scopes are
  // different.
  // if (scopes.includes(UserScopes.Bot)) {
  //   const botChatlistPath = '/bot/chatrooms';
  //   renderedChatroomLinks.push(
  //     <MenuItem
  //       key={botChatlistPath}
  //       as={RouterLink}
  //       to={botChatlistPath}
  //       {...styleProps.link}
  //     >
  //       Bot Chatlist
  //     </MenuItem>
  //   );
  // }

  const renderedCustomerLinks: JSX.Element[] = [];
  if (scopes.includes(UserScopes.User)) {
    for (const path in customerSupPaths) {
      renderedCustomerLinks.push(
        <MenuItem
          key={customerSupPaths[path]}
          as={RouterLink}
          to={customerSupPaths[path]}
          {...styleProps.link}
        >
          {path}
        </MenuItem>,
      );
    }
  }

  const renderedUgcLinks: JSX.Element[] = [];
  if (scopes.includes(UserScopes.Post)) {
    for (const path in ugcPaths) {
      renderedUgcLinks.push(
        <MenuItem
          key={ugcPaths[path]}
          as={RouterLink}
          to={ugcPaths[path]}
          {...styleProps.link}
        >
          {path}
        </MenuItem>,
      );
    }
  }

  const renderedDashboardLinks: JSX.Element[] = [];

  const teamDashboardPath = '/dashboard/team';
  renderedDashboardLinks.push(
    <MenuItem
      key={teamDashboardPath}
      as={RouterLink}
      to={teamDashboardPath}
      {...styleProps.link}
    >
      Team Scoreboard
    </MenuItem>,
  );

  if (scopes.includes(UserScopes.Post)) {
    const contentDashboardPath = '/dashboard/content';
    renderedDashboardLinks.push(
      <MenuItem
        key={contentDashboardPath}
        as={RouterLink}
        to={contentDashboardPath}
        {...styleProps.link}
      >
        Content
      </MenuItem>,
    );
  }

  if (scopes.includes(UserScopes.User)) {
    const nycDashboardPath = '/dashboard/nyc';
    renderedDashboardLinks.push(
      <MenuItem
        key={nycDashboardPath}
        as={RouterLink}
        to={nycDashboardPath}
        {...styleProps.link}
      >
        NYC
      </MenuItem>,
    );
  }

  const renderedMenu = isSignedIn ? (
    <Flex {...styleProps.linkWrapper}>
      {!isSmallerThan900 && renderedAPILinks.length > 0 && (
        <Menu {...styleProps.menuWrapper}>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            {...styleProps.menuButtonFirst}
          >
            API
          </MenuButton>
          <MenuList {...styleProps.menuList}>{renderedAPILinks}</MenuList>
        </Menu>
      )}
      {!isSmallerThan900 && renderedUgcLinks.length > 0 && (
        <Menu {...styleProps.menuWrapper}>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            {...styleProps.menuButtonFirst}
          >
            UGC
          </MenuButton>
          <MenuList {...styleProps.menuList}>{renderedUgcLinks}</MenuList>
        </Menu>
      )}
      {!isSmallerThan900 && renderedDashboardLinks.length > 0 && (
        <Menu {...styleProps.menuWrapper}>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            {...styleProps.menuButtonFirst}
          >
            Tags
          </MenuButton>
          <MenuList {...styleProps.menuList}>
            <MenuItem as={RouterLink} to={'/tags'} {...styleProps.link}>
              Manage Tags
            </MenuItem>
          </MenuList>
        </Menu>
      )}
      {!isSmallerThan900 && renderedDashboardLinks.length > 0 && (
        <Menu {...styleProps.menuWrapper}>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            {...styleProps.menuButtonFirst}
          >
            Dashboard
          </MenuButton>
          <MenuList {...styleProps.menuList}>{renderedDashboardLinks}</MenuList>
        </Menu>
      )}
      {!isSmallerThan900 && renderedChatroomLinks.length > 0 && (
        <Menu {...styleProps.menuWrapper}>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            {...styleProps.menuButtonFirst}
          >
            Chatrooms
          </MenuButton>
          <MenuList {...styleProps.menuList}>{renderedChatroomLinks}</MenuList>
        </Menu>
      )}
      {!isSmallerThan900 && renderedCustomerLinks.length > 0 && (
        <Menu {...styleProps.menuWrapper}>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            {...styleProps.menuButtonFirst}
          >
            Users
          </MenuButton>
          <MenuList {...styleProps.menuList}>{renderedCustomerLinks}</MenuList>
        </Menu>
      )}
      {!isSmallerThan900 && renderedMemeLinks.length > 0 && (
        <Menu {...styleProps.menuWrapper}>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            {...styleProps.menuButtonFirst}
          >
            Memes
          </MenuButton>
          <MenuList {...styleProps.menuList}>{renderedMemeLinks}</MenuList>
        </Menu>
      )}
      <ColorModeSwitcher {...styleProps.themeSwitcher} />
      <Menu isLazy>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon {...styleProps.hamIcon} />}
          {...styleProps.menuButtonLast}
        />
        <MenuList {...styleProps.menuList}>
          {isSmallerThan900 && renderedUgcLinks.length > 0
            ? renderedUgcLinks
            : []}
          {isSmallerThan900 && renderedUgcLinks.length > 0 ? (
            <MenuDivider />
          ) : null}
          {isSmallerThan900 && renderedDashboardLinks.length > 0
            ? renderedDashboardLinks
            : []}
          {isSmallerThan900 && renderedDashboardLinks.length > 0 ? (
            <MenuDivider />
          ) : null}
          {isSmallerThan900 && renderedChatroomLinks.length > 0
            ? renderedChatroomLinks
            : []}
          {isSmallerThan900 && renderedChatroomLinks.length > 0 ? (
            <MenuDivider />
          ) : null}
          {isSmallerThan900 && renderedCustomerLinks.length > 0
            ? renderedCustomerLinks
            : []}
          {isSmallerThan900 && renderedCustomerLinks.length > 0 ? (
            <MenuDivider />
          ) : null}
          {isSmallerThan900 && renderedMemeLinks.length > 0
            ? renderedMemeLinks
            : []}
          {isSmallerThan900 && renderedMemeLinks.length > 0 ? (
            <MenuDivider />
          ) : null}
          <MenuItem as={RouterLink} to="/" {...styleProps.link}>
            Home
          </MenuItem>
          <MenuItem as={RouterLink} to="/profile" {...styleProps.link}>
            My Profile
          </MenuItem>
          <MenuItem onClick={signOut} {...styleProps.link}>
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  ) : (
    <ColorModeSwitcher />
  );

  return (
    <Flex {...styleProps.navWrapper}>
      <Link
        as={RouterLink}
        to={isSignedIn ? '/' : '/login'}
        {...styleProps.brandLink}
      >
        <Flex {...styleProps.brandWrapper}>
          <Image src="/logo.png" alt="Logo" {...styleProps.logo} />
          <Text {...styleProps.name}>Schmooze</Text>
        </Flex>
      </Link>
      {renderedMenu}
    </Flex>
  );
};

export default NavigationBar;
