import { lazy, LazyExoticComponent } from 'react';

import UserScopes from './config/userScopes';

import ProplessFC from './types/ProplessFC';

const AdditionalTaggingUgcMemesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AdditionalTaggingUgcMemes" */ './pages/AdditionalTaggingUgcMemesPage'
    ),
);

const AddMemePage = lazy(
  () => import(/* webpackChunkName: "AddYourMeme" */ './pages/AddMemePage'),
);

const BotChatPage = lazy(
  () => import(/* webpackChunkName: "BotChatroom" */ './pages/BotChatPage'),
);

const ContentDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ContentDashboard" */ './pages/ContentDashboardPage'
    ),
);

const CustomerChatPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CustomerChatroom" */ './pages/CustomerChatPage'
    ),
);

const CustomerSupportAnalyticsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CustomerChatList" */ './pages/CustomerSupportAnalytics'
    ),
);

const SupportDashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CustomerChatList" */ './pages/SupportDashboardPage'
    ),
);

const CustomerProfilePage = lazy(
  () =>
    import(
      /* webpackChunkName: "CustomerProfile" */ './pages/CustomerProfilePage'
    ),
);

const CustomerSearchPage = lazy(
  () =>
    import(
      /* webpackChunkName: "CustomerSearch" */ './pages/CustomerSearchPage'
    ),
);

const InternalReviewMemesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "InternalReviewMemes" */ './pages/InternalReviewMemes'
    ),
);

const InstaHandlePage = lazy(
  () =>
    import(
      /* webpackChunkName: "InstaHandlesPage" */ './pages/InstaHandlePage'
    ),
);

const LogoutPage = lazy(
  () => import(/* webpackChunkName: "Logout" */ './pages/LogoutPage'),
);

const MemePage = lazy(
  () => import(/* webpackChunkName: "Meme" */ './pages/MemePage'),
);

const MemesPage = lazy(
  () => import(/* webpackChunkName: "Home" */ './pages/MemesPage'),
);

const NycDashboardPage = lazy(
  () =>
    import(/* webpackChunkName: "NycDashboard" */ './pages/NycDashboardPage'),
);

const ProfilePage = lazy(
  () => import(/* webpackChunkName: "Profile" */ './pages/ProfilePage'),
);

const PublishMemePage = lazy(
  () =>
    import(/* webpackChunkName: "PublishScraped" */ './pages/PublishMemePage'),
);

const RedditMemesPage = lazy(
  () => import(/* webpackChunkName: "RedditMemes" */ './pages/RedditMemesPage'),
);

const ReportedMemesPage = lazy(
  () =>
    import(/* webpackChunkName: "ReportedMemes" */ './pages/ReportedMemesPage'),
);

const ReviewCustomersPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ReviewCustomers" */ './pages/ReviewCustomersPage'
    ),
);

const TagManagementPage = lazy(
  () =>
    import(/* webpackChunkName: "TagManagement" */ './pages/TagManagementPage'),
);

const TwitterMemesPage = lazy(
  () =>
    import(/* webpackChunkName: "TwitterMemes" */ './pages/TwitterMemesPage'),
);

const TeamOutreachChatPage = lazy(
  () =>
    import(
      /* webpackChunkName: "TeamOutreachChat" */ './pages/TeamOutreachChatPage'
    ),
);

const AiChatPage = lazy(
  () =>
    import(
      /* webpackChunkName: "TeamOutreachChatList" */ './pages/AiChatPage'
    ),
);

const AiChatListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "TeamOutreachChatList" */ './pages/AiChatsPage'
    ),
);

const CommunityChatListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "TeamOutreachChatList" */ './pages/CommunityChatPage'
    ),
);

const TeamOutreachChatListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "TeamOutreachChatList" */ './pages/TeamOutreachChatListPage'
    ),
);

const TiktokMemesPage = lazy(
  () => import(/* webpackChunkName: "TiktokMemes" */ './pages/TiktokMemesPage'),
);

const FaceEmbeddingsPage = lazy(
  () => import(/* webpackChunkName: "UserMap" */ './pages/FaceEmbeddingsPage'),
);

const UserMachPage = lazy(
  () => import(/* webpackChunkName: "UserMap" */ './pages/UserMachPage'),
);


const ClaudePage = lazy(
  () => import(/* webpackChunkName: "UserMap" */ './pages/ClaudePage'),
);

const UserMapPage = lazy(
  () => import(/* webpackChunkName: "UserMap" */ './pages/UserMapPage'),
);

const UgcMemesPage = lazy(
  () => import(/* webpackChunkName: "UgcMemes" */ './pages/UgcMemesPage'),
);

const SanicSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/SanicSwaggerAPI'
    ),
);

const ContentSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/ContentSwaggerAPI'
    ),
);

const IronSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/IronSwaggerAPI'
    ),
);

const DrogonAppSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/DrogonAppSwaggerAPI'
    ),
);

const ViserionAzSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/ViserionAzSwaggerAPI'
    ),
);
const IronAppSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/IronAppSwaggerAPI'
    ),
);
const VarysAppSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/VarysAppSwaggerAPI'
    ),
);
const ViserionAppSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/ViserionAppSwaggerAPI'
    ),
);
const RhaegalSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/RhaegalSwaggerAPI'
    ),
);

const VhagarSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/VhagarSwaggerAPI'
    ),
);

const DrogonAzSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/DrogonAzSwaggerAPI'
    ),
);
const VaryzAzSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/VaryzAzSwaggerAPI'
    ),
);
const IronBankAzSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/IronBankAzSwaggerAPI'
    ),
);

const ViserionSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/ViserionSwaggerAPI'
    ),
);

const VhagarUgcSwaggerAPI = lazy(
  () =>
    import(
      /* webpackChunkName: "SwaggerAPI" */ './pages/SwaggerDocs/VhagarUgcSwaggerAPI'
    ),
);

const SourceManagementPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SourceManagement" */ './pages/SourceManagementPage'
    ),
);

const TeamDashboardPage = lazy(
  () => import(/* webpackChunkName: "TeamDashboard" */ './pages/TeamDashboard'),
);

const InstaDownloader = lazy(
  () =>
    import(/* webpackChunkName: "InstaDownloader" */ './pages/InstaDownloader'),
);

const DuplicatesPage = lazy(
  () => import(/* webpackChunkName: "Duplicates" */ './pages/Duplicates'),
);

const MassNotificationsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "MassNotifications" */ './pages/MassNotificationsPage'
    ),
);

type PageRouteObject = {
  path: string;

  Component:
    | LazyExoticComponent<() => JSX.Element>
    | LazyExoticComponent<ProplessFC>;

  scope?: UserScopes;
};

type SCOPE_TYPE = [string, LazyExoticComponent<() => JSX.Element>][];

const USER_SCOPES = [
  ['/user/map', UserMapPage],
  ['/user/mach', UserMachPage],
  ['/user/claude', ClaudePage],
  ['/user/:userId', CustomerProfilePage],
  ['/user/face-embeddings', FaceEmbeddingsPage],
  ['/customer/search', CustomerSearchPage],
  ['/customer/review', ReviewCustomersPage],
  ['/dashboard/nyc', NycDashboardPage],
] as SCOPE_TYPE;

const CHAT_SCOPES = [
  ['/customer/chatroom/:userId', CustomerChatPage],
  ['/customer/chatrooms', SupportDashboardPage],
  ['/outreach/chatrooms', TeamOutreachChatListPage],
  ['/ai/chatroom/:chatroomId', AiChatPage],
  ['/ai/chatrooms', AiChatListPage],
  ['/community/chatrooms', CommunityChatListPage],
  ['/outreach/:chatroomId', TeamOutreachChatPage],
  ['/outreach/mass-notifications', MassNotificationsPage],
  ['/support/analytics', CustomerSupportAnalyticsPage],
] as SCOPE_TYPE;

const POST_SCOPES = [
  ['/add', AddMemePage],
  ['/publish', PublishMemePage],
  ['/meme/:id', MemePage],
  ['/profile', ProfilePage],
  ['/dashboard/content', ContentDashboardPage],
  ['/dashboard/team', TeamDashboardPage],
  ['/memes/reported', ReportedMemesPage],
  ['/memes/user', UgcMemesPage],
  ['/memes/user/internal-review', InternalReviewMemesPage],
  ['/memes/user/additional-tagging', AdditionalTaggingUgcMemesPage],
  ['/r/:sub', RedditMemesPage],
  ['/tiktok', TiktokMemesPage],
  ['/twitter', TwitterMemesPage],
] as SCOPE_TYPE;

const BOT_SCOPES = [['/bot/chatroom/:chatroomId', BotChatPage]] as SCOPE_TYPE;

const TAG_SCOPES = [['/tags', TagManagementPage]] as SCOPE_TYPE;

const ALL_SCOPES = [
  ['/c', SourceManagementPage],
  ['/c/:platform', SourceManagementPage],
  ['/api/sanic', SanicSwaggerAPI],
  ['/api/content', ContentSwaggerAPI],
  ['/api/iron', IronSwaggerAPI],
  ['/api/drogon-ugc', DrogonAppSwaggerAPI],
  ['/api/viserion-az', ViserionAzSwaggerAPI],
  ['/api/iron-app', IronAppSwaggerAPI],
  ['/api/varys-app', VarysAppSwaggerAPI],
  ['/api/viserion-app', ViserionAppSwaggerAPI],
  ['/api/rhaegal', RhaegalSwaggerAPI],
  ['/api/drogon-az', DrogonAzSwaggerAPI],
  ['/api/varys-az', VaryzAzSwaggerAPI],
  ['/api/iron-az', IronBankAzSwaggerAPI],
  ['/api/viserion-ugc', ViserionSwaggerAPI],
  ['/api/vhagar', VhagarSwaggerAPI],
  ['/api/vhagar-ugc', VhagarUgcSwaggerAPI],
  ['/insta-save', InstaDownloader],
  ['/c/instagram/:handle', InstaHandlePage],
  ['/templates', DuplicatesPage],
  ['/logout', LogoutPage],
  ['/', MemesPage],
] as SCOPE_TYPE;

const pages: PageRouteObject[] = [];

const scopes: [UserScopes | null, SCOPE_TYPE][] = [
  [UserScopes.User, USER_SCOPES],
  [UserScopes.Chat, CHAT_SCOPES],
  [UserScopes.Post, POST_SCOPES],
  [UserScopes.Bot, BOT_SCOPES],
  [UserScopes.Tag, TAG_SCOPES],
  [null, ALL_SCOPES],
];

scopes.forEach(([scope, scopePages]) => {
  pages.push(
    ...scopePages.map(([path, Component]) =>
      scope ? { path, Component, scope } : { path, Component },
    ),
  );
});

export default pages;
